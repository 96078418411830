import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import { useMetersHistory } from "../../../store/meters";
import { useEffect, useState } from "react";
import SectionContainer from "../../../components/container/section-container";
import MeterItem from "./meter-item";
import MetersHistoryModal from "./meters-history-modal";

interface Props {
    id: string;
}

const MetersHistory = ({ id }: Props) => {
    const { meters, getMetersHistory, loading, error, totalMeters } =
        useMetersHistory((state) => state);
    const [showAll, setShowAll] = useState<boolean>(false);

    useEffect(() => {
        getMetersHistory(id);
    }, [id]);

    return (
        <>
            <SectionContainer>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            color: enerbitColors.primary.main,
                            fontSize: "18px",
                        }}
                    >
                        Historial de medidores
                    </Typography>
                    {totalMeters > 3 && !loading && !error && (
                        <Button
                            sx={{
                                py: "6px !important",
                                px: "16px !important",
                                borderRadius: "8px !important",
                                height: "26px !important",
                            }}
                            variant="outlined"
                            color="secondary"
                            onClick={() => setShowAll(true)}
                        >
                            Ver todos
                        </Button>
                    )}
                </Box>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                <Box>
                    {!loading && !error && totalMeters > 0 && (
                        <>
                            {meters
                                .sort(
                                    (a, b) =>
                                        Number(b.active) - Number(a.active),
                                )
                                .slice(0, 3)
                                .map((meter) => (
                                    <Box
                                        key={meter.serial}
                                        sx={{
                                            mb: 1,
                                        }}
                                    >
                                        <MeterItem meter={meter} />
                                    </Box>
                                ))}
                        </>
                    )}
                    {loading && !error && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && error && (
                        <CustomAlert
                            severity="error"
                            text={"Error cargando historial de medidores."}
                            onClose={() => {}}
                        />
                    )}
                </Box>
            </SectionContainer>
            <MetersHistoryModal
                open={showAll}
                id={id}
                handleClose={() => {
                    setShowAll(false);
                }}
            />
        </>
    );
};

export default MetersHistory;
