import {
    Box,
    CheckIcon,
    ClearIcon,
    enerbitColors,
    Grid,
    IconButton,
    Popover,
    styled,
    Typography,
} from "@enerbit/base";
import { ReactNode, useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { Usages, Usagestatus } from "../../../models/usages";

const StyledPopover = styled(Popover)(() => ({
    "& .MuiPaper-root": {
        border: `1px solid ${enerbitColors.primary.main}`,
    },
}));

interface Props {
    measurement: Usages;
    hour: string;
    date: string;
}

const statusesColors: Record<
    Usagestatus,
    { bgcolor?: string; icon: ReactNode }
> = {
    exists: {
        bgcolor: enerbitColors.success[100],
        icon: (
            <CheckIcon
                sx={{ fontSize: "16px", color: enerbitColors.success.main }}
            />
        ),
    },
    estimated: {
        bgcolor: enerbitColors.warning[100],
        icon: (
            <CheckIcon
                sx={{ fontSize: "16px", color: enerbitColors.warning.main }}
            />
        ),
    },
    missing: {
        bgcolor: enerbitColors.error[100],
        icon: (
            <ClearIcon
                sx={{ fontSize: "16px", color: enerbitColors.error.main }}
            />
        ),
    },
    future: {
        bgcolor: enerbitColors.neutral[100],
        icon: (
            <RemoveIcon
                sx={{ fontSize: "16px", color: enerbitColors.neutral.main }}
            />
        ),
    },
};

const MeasurementState = ({ measurement, hour, date }: Props) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (
            measurement.status === Usagestatus.FUTURE ||
            measurement.status === Usagestatus.MISSING
        ) {
            return;
        }
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <>
            <IconButton
                onClick={handleClick}
                sx={{
                    backgroundColor:
                        measurement.status &&
                        statusesColors[measurement.status].bgcolor,
                    borderRadius: "8px",
                    height: "32px",
                    width: "32px",
                    cursor:
                        measurement.status === Usagestatus.FUTURE ||
                        measurement.status === Usagestatus.MISSING
                            ? "default"
                            : "pointer",
                    ":hover": {
                        backgroundColor:
                            measurement.status &&
                            statusesColors[measurement.status].bgcolor,
                    },
                }}
            >
                {measurement.status && statusesColors[measurement.status].icon}
            </IconButton>
            <StyledPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <Box sx={{ p: "8px", width: "300px" }}>
                    <Box
                        sx={{
                            backgroundColor: enerbitColors.primary.main,
                            m: "-8px",
                            p: 1,
                        }}
                    >
                        <Typography sx={{ color: "#FFF", fontWeight: 700 }}>
                            Fecha: {date}
                        </Typography>
                        <Typography sx={{ color: "#FFF", fontWeight: 700 }}>
                            Hora: {hour}
                        </Typography>
                    </Box>
                    <Grid
                        container
                        rowSpacing={1}
                        columnSpacing={1}
                        sx={{ mt: 1 }}
                    >
                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Ener. Activa Importada
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {measurement.active_energy_imported}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Ener. Activa Exportada
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {measurement.active_energy_exported}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Ener. Reactiva Importada
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {measurement.reactive_energy_imported}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                Ener. Reactiva Exportada
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {measurement.reactive_energy_exported}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </StyledPopover>
        </>
    );
};

export default MeasurementState;
