import { create } from "zustand";
import { OrdersState, SearchOrdersParams } from "../models";
import { getOrdersService } from "../services";

export const useOrdersState = create<OrdersState>()((set) => ({
    loading: false,
    error: false,
    orders: { items: [], currentPage: 0, totalPages: 0 },
    getOrders: async ({ id, page }: SearchOrdersParams) => {
        set({ loading: true, error: false });
        try {
            const orders = await getOrdersService({ id, page });
            set({
                orders: {
                    items: orders.items,
                    currentPage: orders.currentPage,
                    totalPages: orders.totalPages,
                },
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
