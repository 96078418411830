import StyledModal from "../../../components/container/styled-modal";
import { useSubmeasurements } from "../../../store/submeasurements";
import { useEffect } from "react";
import { generateRandomId } from "../../../utils/common";
import { CustomAlert, Pagination, Skeleton, Stack } from "@enerbit/base";
import PaginationContainer from "../../../components/container/pagination-container";
import MeasurementPointItem from "./measurement-point-item";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: string;
}

const ChildPointsModal = ({ open, handleClose, id }: Props) => {
    const {
        loading,
        error,
        getChildPoints,
        measurementPoints,
        currentPage,
        totalPages,
    } = useSubmeasurements((state) => state);

    useEffect(() => {
        getChildPoints(id, 0);
    }, [id]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        getChildPoints(id, value - 1);
    };

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            title="Puntos de medida hijos"
        >
            {loading && (
                <>
                    {[...Array(7)].map(() => (
                        <Skeleton
                            variant="rectangular"
                            key={generateRandomId()}
                            height={56}
                            sx={{ mb: 2 }}
                        />
                    ))}
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={
                        "Error cargando información de los puntos de medida hijo."
                    }
                    onClose={() => {}}
                />
            )}
            {!loading && !error && (
                <>
                    {measurementPoints.map((mp) => (
                        <MeasurementPointItem key={mp.id} mp={mp} />
                    ))}
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </StyledModal>
    );
};

export default ChildPointsModal;
