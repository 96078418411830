import { api } from "@enerbit/base";
import { GetUsagesParams, Usages } from "../models/usages";

export const getUsagesByMPServices = async ({
    id,
    since,
    until,
}: GetUsagesParams) => {
    try {
        const params = { since, until };
        const res = await api.get<Usages[]>(
            `/odin/measurement-point/schedules/${id}`,
            {
                params,
            },
        );
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingSchedules");
    }
};
