import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Skeleton,
    Typography,
} from "@enerbit/base";
import { useSubmeasurements } from "../../../store/submeasurements";
import { useEffect, useState } from "react";
import SectionContainer from "../../../components/container/section-container";
import MeasurementPointItem from "./measurement-point-item";
import ChildPointsModal from "./child-points-modal";

interface Props {
    id: string;
}

const Submeasurements = ({ id }: Props) => {
    const { loading, error, getChildPoints, measurementPoints, totalChilds } =
        useSubmeasurements((state) => state);
    const [showAll, setShowAll] = useState<boolean>(false);

    useEffect(() => {
        getChildPoints(id, 0);
    }, [id]);

    return (
        <>
            {loading && <Skeleton variant="rectangular" height={"180px"} />}
            {!loading && !error && totalChilds > 0 && (
                <>
                    <SectionContainer>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.primary.main,
                                fontSize: "18px",
                            }}
                        >
                            Puntos de medida hijos
                        </Typography>
                        <Divider sx={{ my: 1, mx: "-12px" }} />
                        {loading && !error && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <CircularProgress />
                            </Box>
                        )}
                        {!loading && error && (
                            <CustomAlert
                                severity="error"
                                text={
                                    "Error cargando información de los puntos de medida hijo."
                                }
                                onClose={() => {}}
                            />
                        )}
                        {!loading && !error && totalChilds === 0 && (
                            <CustomAlert
                                severity="info"
                                text={"Sin puntos de medida hijos."}
                                onClose={() => {}}
                            />
                        )}
                        {!loading && !error && totalChilds > 0 && (
                            <>
                                {measurementPoints.slice(0, 3).map((mp) => (
                                    <Box key={mp.id} sx={{ mb: 1 }}>
                                        <MeasurementPointItem mp={mp} />
                                    </Box>
                                ))}
                                {totalChilds > 3 && (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Button
                                            variant="text"
                                            color="secondary"
                                            onClick={() => setShowAll(true)}
                                        >
                                            Ver todos
                                        </Button>
                                    </Box>
                                )}
                            </>
                        )}
                    </SectionContainer>
                    <ChildPointsModal
                        open={showAll}
                        handleClose={() => setShowAll(false)}
                        id={id}
                    />
                </>
            )}
        </>
    );
};

export default Submeasurements;
