import {
    Box,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Grid,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { useRelatedData } from "../../../store/related-data";
import { useEffect } from "react";

interface Props {
    id: string;
}

const ClientData = ({ id }: Props) => {
    const { loading, getMpFrontier, error, frontiers } = useRelatedData(
        (state) => state,
    );

    useEffect(() => {
        getMpFrontier(id);
    }, [id]);

    return (
        <SectionContainer>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "18px",
                }}
            >
                Cliente/Lead
            </Typography>
            <Divider sx={{ my: 1, mx: "-12px" }} />
            {loading && !error && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={"Error cargando información del lead o cliente."}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && frontiers.length === 0 && (
                <CustomAlert
                    severity="info"
                    text={"No se encontró la información del lead o cliente."}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && frontiers.length > 0 && (
                <Grid container rowSpacing={1}>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Nombre
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {frontiers[0].DisplayName ?? "Desconocido"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Correo
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {frontiers[0].EmailFrom ?? "Desconocido"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            sx={{
                                color: enerbitColors.neutral.main,
                                fontSize: "12px",
                            }}
                        >
                            Ubicación
                        </Typography>
                        <Typography
                            sx={{
                                fontWeight: 700,
                                color: enerbitColors.neutral[900],
                            }}
                        >
                            {frontiers[0].Street ?? "Desconocido"}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            {/* <Button
                fullWidth
                variant="outlined"
                color="primary"
                sx={{ mt: 2, borderRadius: "4px !important" }}
            >
                Más información
            </Button> */}
        </SectionContainer>
    );
};

export default ClientData;
