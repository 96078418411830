// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rs-calendar-table-cell-selected .rs-calendar-table-cell-content {
    background-color: #53358E;
}

.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {
    background-color: #D9D2E6;
}

.rs-btn-primary {
    background-color: #53358E;  
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
    box-shadow: inset 0 0 0 1px #53358E;
}

.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled {
    background-color: #53358E; 
}

.rs-calendar-table-cell-in-range::before {
    background-color: #D9D2E6;
}`, "",{"version":3,"sources":["webpack://./src/styles/date-range-picker.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mCAAmC;AACvC;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".rs-calendar-table-cell-selected .rs-calendar-table-cell-content {\n    background-color: #53358E;\n}\n\n.rs-picker-popup .rs-calendar .rs-calendar-table-cell-selected:hover .rs-calendar-table-cell-content {\n    background-color: #D9D2E6;\n}\n\n.rs-btn-primary {\n    background-color: #53358E;  \n}\n\n.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {\n    box-shadow: inset 0 0 0 1px #53358E;\n}\n\n.rs-btn-primary:disabled, .rs-btn-primary.rs-btn-disabled {\n    background-color: #53358E; \n}\n\n.rs-calendar-table-cell-in-range::before {\n    background-color: #D9D2E6;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
