import { enerbitColors } from "@enerbit/base";

export const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm";
export const DATE_TIME_INPUT_FORMAT = "YYYY-MM-DDTHH:mm:ssZ"

export const ORDER_TYPES_TO_NAMES: Record<
    string,
    { label: string; font: string | undefined; bgcolor: string | undefined }
> = {
    pending: {
        label: "Pendiente",
        font: enerbitColors.warning.main,
        bgcolor: enerbitColors.warning[100],
    },
    done: {
        label: "Completada",
        font: enerbitColors.success.main,
        bgcolor: enerbitColors.success[100],
    },
    cancel: {
        label: "Cancelada",
        font: enerbitColors.error.main,
        bgcolor: enerbitColors.error[100],
    },
    partial_done: {
        label: "Parcialmente completada",
        font: "#FFF",
        bgcolor: "#3B82F6",
    },
    pending_assignment: {
        label: "Pendiente por asignar",
        font: "#667085",
        bgcolor: "#E4E7EC",
    },
};
