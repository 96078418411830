import { Box, Button } from "@enerbit/base";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";
import { navigateToUrl } from "single-spa";

const Actions = () => {
    const goToOrders = () => {
        navigateToUrl("/#/orders");
    };
    return (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <Button
                onClick={goToOrders}
                color="secondary"
                variant="contained"
                startIcon={<BuildOutlinedIcon />}
            >
                Generar OS
            </Button>
            {/* <Button
                color="secondary"
                variant="outlined"
                startIcon={<AutorenewIcon />}
            >
                Recuperación
            </Button> */}
            {/* <Button
                color="secondary"
                variant="outlined"
                startIcon={<ContentCopyIcon />}
            >
                Copiar datos del PM
            </Button> */}
        </Box>
    );
};

export default Actions;
