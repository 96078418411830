import moment from "moment";
import { Usages, Usagestatus } from "../models/usages";

export const formatUsages = (schedules: Usages[]) => {
    // 1. Agrupar por días
    const groupedByDate = groupUsagesByDate(schedules);

    // 2. Verificar horas faltantes
    const results = getHourStatus(groupedByDate);

    return results;
};

const groupUsagesByDate = (data: Usages[]) => {
    return data.reduce(
        (acc, measurement) => {
            const date = moment(measurement.time_start).format("YYYY-MM-DD");
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(measurement);
            return acc;
        },
        {} as Record<string, Usages[]>,
    );
};

const getHourStatus = (usages: Record<string, Usages[]>) => {
    const currentTime = moment();

    for (const [date, measurements] of Object.entries(usages)) {
        const hoursFound: Set<string> = new Set();

        // Marcamos todas las mediciones encontradas y las categorizamos
        measurements.forEach((measurement) => {
            const startTime = moment(measurement.time_start);
            const hourLabel = startTime.format("HH:mm");

            hoursFound.add(hourLabel);

            if (measurement.is_estimated) {
                measurement.status = Usagestatus.ESTIMATED;
            } else if (startTime.isAfter(currentTime)) {
                measurement.status = Usagestatus.FUTURE;
            } else {
                measurement.status = Usagestatus.EXISTS;
            }
        });

        // Calculamos las horas faltantes y las marcamos
        const allHours = Array.from(
            { length: 24 },
            (_, i) => `${i.toString().padStart(2, "0")}:00`,
        );

        allHours.forEach((hour) => {
            if (!hoursFound.has(hour)) {
                const missingMeasurement: Usages = {
                    active_energy_imported: 0,
                    reactive_energy_imported: 0,
                    active_energy_exported: 0,
                    reactive_energy_exported: 0,
                    measurement_point_id: "",
                    time_start: `${date}T${hour}:00-05:00`,
                    time_end: "",
                    is_estimated: false,
                };
                if (
                    moment(`${date}T${hour}:00-05:00`).isAfter(currentTime) ||
                    moment(`${date}T${hour}:00-05:00`).isBetween(
                        currentTime.startOf("hour"),
                        currentTime.endOf("hour"),
                        "hour",
                        "[]",
                    )
                ) {
                    missingMeasurement["status"] = Usagestatus.FUTURE;
                } else {
                    missingMeasurement["status"] = Usagestatus.MISSING;
                }

                usages[date].push(missingMeasurement);
            }
        });
    }

    return usages;
};
