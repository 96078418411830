import { api } from "@enerbit/base";
import { ElectricitySupplyServiceResponse } from "../models/ess";

export const getEssByMpService = async (mpId: string) => {
    try {
        const res = await api.get<ElectricitySupplyServiceResponse>(
            `/electricity-supply-service/manager/electricity-supply-services/?measurement_point_id=${mpId}`,
        );

        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingEss");
    }
};
