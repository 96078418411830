import { create } from "zustand";
import { DATE_TIME_INPUT_FORMAT } from "../common";
import moment from "moment";
import { GetUsagesParams, UsagesState } from "../models/usages";
import { getUsagesByMPServices } from "../services/usages";
import { formatUsages } from "../utils";

export const useUsagesState = create<UsagesState>()((set) => ({
    loading: false,
    error: false,
    usages: null,
    getUsages: async ({ id, since, until }: GetUsagesParams) => {
        set({ loading: true });
        try {
            const usages = await getUsagesByMPServices({
                id,
                since,
                until,
            });

            const formattedUsages = formatUsages(
                usages.map((usg) => ({
                    ...usg,
                    time_start: moment(usg.time_start)
                        .utcOffset(-5)
                        .format(DATE_TIME_INPUT_FORMAT),
                    time_end: moment(usg.time_end)
                        .utcOffset(-5)
                        .format(DATE_TIME_INPUT_FORMAT),
                })),
            );

            set({ usages: formattedUsages });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
