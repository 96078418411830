import {
    Box,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import { useMetersHistory } from "../../../store/meters";
import { useEffect } from "react";
import SectionContainer from "../../../components/container/section-container";
import MeterItem from "./meter-item";

interface Props {
    id: string;
}

const MetersHistory = ({ id }: Props) => {
    const { meters, getMetersHistory, loading, error } = useMetersHistory(
        (state) => state,
    );

    useEffect(() => {
        getMetersHistory(id);
    }, []);

    return (
        <>
            <SectionContainer>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "18px",
                    }}
                >
                    Historial de medidores
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                <Box>
                    {!loading && !error && (
                        <>
                            {meters.map((meter) => (
                                <MeterItem key={meter.serial} meter={meter} />
                            ))}
                        </>
                    )}
                    {loading && !error && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                    {!loading && error && (
                        <CustomAlert
                            severity="error"
                            text={"Error cargando historial de medidores."}
                            onClose={() => {}}
                        />
                    )}
                </Box>
            </SectionContainer>
        </>
    );
};

export default MetersHistory;
