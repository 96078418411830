import {
    ArrowForwardIcon,
    Box,
    CircularProgress,
    Divider,
    enerbitColors,
    IconButton,
    Typography,
} from "@enerbit/base";
import { useRelatedData } from "../../../store/related-data";
import { navigateToUrl } from "single-spa";
import { useElectricitySupplyService } from "../../../store/ess";
import { useEffect } from "react";

interface Props {
    id: string;
}

const RelatedInfo = ({ id }: Props) => {
    const {
        loading: loadingFrt,
        error: errorFrt,
        frontiers,
    } = useRelatedData((state) => state);

    const {
        loading: loadingEss,
        error: errorEss,
        electricitySuppluServices,
        getEssByMp,
    } = useElectricitySupplyService((state) => state);

    const goToFrtDetail = (frt: string) => {
        navigateToUrl(`/#/frontiers/${frt}`);
    };

    const goToEssDetail = (id: string) => {
        navigateToUrl(`/#/electricity-supply-service/attention/${id}`);
    };

    useEffect(() => {
        getEssByMp(id);
    }, [id]);

    return (
        <Box>
            <Typography
                sx={{
                    fontWeight: 700,
                    color: enerbitColors.primary.main,
                    fontSize: "18px",
                }}
            >
                Información relacionada
            </Typography>
            <Box
                sx={{
                    borderRadius: "8px",
                    bgcolor: enerbitColors.primary.main,
                    padding: "16px 12px 16px 12px",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: "white",
                            }}
                        >
                            Frontera
                        </Typography>
                        {loadingFrt && !errorFrt && (
                            <CircularProgress
                                sx={{ color: "white" }}
                                size={"20px"}
                            />
                        )}
                        {!loadingFrt && !errorFrt && frontiers.length > 0 && (
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: "white",
                                }}
                            >
                                {frontiers[0].frontier_xm_code}
                            </Typography>
                        )}
                        {!loadingFrt && !errorFrt && frontiers.length === 0 && (
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: "white",
                                }}
                            >
                                No se encontró información de la frontera
                            </Typography>
                        )}
                        {!loadingFrt && errorFrt && (
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: "white",
                                }}
                            >
                                Error al consultar información de la frontera
                            </Typography>
                        )}
                    </Box>
                    {!loadingFrt && !errorFrt && frontiers.length > 0 && (
                        <IconButton
                            onClick={() => {
                                goToFrtDetail(frontiers[0].frontier_xm_code);
                            }}
                            sx={{
                                backgroundColor: "#FF7705",
                                borderRadius: "12px",
                                height: "32px",
                                width: "32px",
                                ":hover": {
                                    backgroundColor: "#FF7705",
                                },
                            }}
                        >
                            <ArrowForwardIcon sx={{ color: "white" }} />
                        </IconButton>
                    )}
                </Box>
                <Divider sx={{ my: 2, backgroundColor: "white" }} />
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                    }}
                >
                    <Box>
                        <Typography
                            sx={{
                                fontSize: "12px",
                                color: "white",
                            }}
                        >
                            Servicio de electricidad
                        </Typography>
                        {loadingEss && !errorEss && (
                            <CircularProgress
                                sx={{ color: "white" }}
                                size={"20px"}
                            />
                        )}
                        {!loadingEss &&
                            !errorEss &&
                            electricitySuppluServices.length > 0 && (
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        color: "white",
                                    }}
                                >
                                    {electricitySuppluServices[0].id}
                                </Typography>
                            )}
                        {!loadingEss &&
                            !errorEss &&
                            electricitySuppluServices.length === 0 && (
                                <Typography
                                    sx={{
                                        fontWeight: 700,
                                        color: "white",
                                    }}
                                >
                                    No se encontró información del servicio de
                                    electricidad.
                                </Typography>
                            )}
                        {!loadingEss && errorEss && (
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: "white",
                                }}
                            >
                                Error al consultar información del servicio de
                                electricidad.
                            </Typography>
                        )}
                    </Box>
                    {!loadingEss &&
                        !errorEss &&
                        electricitySuppluServices.length > 0 && (
                            <IconButton
                                onClick={() => {
                                    goToEssDetail(
                                        electricitySuppluServices[0].id,
                                    );
                                }}
                                sx={{
                                    backgroundColor: "#FF7705",
                                    borderRadius: "12px",
                                    height: "32px",
                                    width: "32px",
                                    ":hover": {
                                        backgroundColor: "#FF7705",
                                    },
                                }}
                            >
                                <ArrowForwardIcon sx={{ color: "white" }} />
                            </IconButton>
                        )}
                </Box>
            </Box>
        </Box>
    );
};

export default RelatedInfo;
