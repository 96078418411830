import { api } from "@enerbit/base";
import { FrontiersResponse } from "../models/frontiers";

export const getMpFrontierService = async (mpId: string) => {
    try {
        const res = await api.get<FrontiersResponse>(
            `/frontiers/frontiers/?measurement_point_id=${mpId}&page=1&size=50`,
        );

        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingFrt");
    }
};
