import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    DownloadOutlinedIcon,
    enerbitColors,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@enerbit/base";
import StatusTag from "../../../components/container/status-tag";
import { CustomProvider, DateRangePicker } from "rsuite";
import { esAR } from "rsuite/esm/locales";

import "rsuite/DateRangePicker/styles/index.css";
import "../../../styles/date-range-picker.css";
import moment from "moment";
import { DATE_TIME_INPUT_FORMAT } from "../../../common";
import { generateCSV, generateRandomId } from "../../../utils";
import MeasurementState from "./measurement-state";
import { useEffect } from "react";
import { useUsagesState } from "../../../store/usages";

interface Props {
    id: string;
}

const hours = new Array(24)
    .fill(0)
    .map((_, index) => (index < 10 ? "0" + index : index.toString()));

const Measurements = ({ id }: Props) => {
    const { loading, error, getUsages, usages } = useUsagesState(
        (state) => state,
    );

    const onOk = (value: [Date, Date]) => {
        const since = moment(value[0])
            .startOf("day")
            .format(DATE_TIME_INPUT_FORMAT);

        let until: string;

        if (moment().isSame(value[1])) {
            until = moment().format(DATE_TIME_INPUT_FORMAT);
        }

        until = moment(value[1])
            .add(1, "day")
            .startOf("day")
            .format(DATE_TIME_INPUT_FORMAT);

        getUsages({ id, since, until });
    };

    const handleDownload = () => {
        if (!usages) return;

        generateCSV(usages, id);
    };

    useEffect(() => {
        const since = moment()
            .subtract(2, "day")
            .startOf("day")
            .format(DATE_TIME_INPUT_FORMAT);
        const until = moment().format(DATE_TIME_INPUT_FORMAT);
        getUsages({ id, since, until });
    }, []);

    return (
        <Box sx={{ mt: 2 }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <Typography sx={{ color: enerbitColors.neutral[700] }}>
                        Estado:
                    </Typography>
                    <StatusTag
                        bgcolor={enerbitColors.success[100] || ""}
                        fontcolor={enerbitColors.success.main || ""}
                    >
                        Dato completo
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.warning[100] || ""}
                        fontcolor={enerbitColors.warning.main || ""}
                    >
                        Dato estimado
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.error[100] || ""}
                        fontcolor={enerbitColors.error.main || ""}
                    >
                        Dato faltante
                    </StatusTag>
                    <StatusTag
                        bgcolor={enerbitColors.neutral[100] || ""}
                        fontcolor={enerbitColors.neutral.main || ""}
                    >
                        Fuera de hora
                    </StatusTag>
                </Box>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "right",
                    my: 2,
                    gap: 2,
                }}
            >
                <CustomProvider locale={esAR}>
                    <DateRangePicker
                        showOneCalendar
                        format="yyyy-MM-dd"
                        size="md"
                        placeholder="Selecciona el rango de fechas"
                        placement="top"
                        showHeader={false}
                        ranges={[]}
                        onOk={onOk}
                        loading={loading}
                        shouldDisableDate={(date) =>
                            moment(date).isAfter(moment())
                        }
                    />
                </CustomProvider>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleDownload}
                >
                    <DownloadOutlinedIcon />
                </Button>
            </Box>
            {loading && (
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                    }}
                >
                    <CircularProgress />
                </Box>
            )}

            {error && !loading && (
                <CustomAlert
                    severity="error"
                    text={"Error cargando datos."}
                    onClose={() => {}}
                    sx={{ mt: 1 }}
                />
            )}
            {usages && !loading && !error && (
                <TableContainer
                    sx={{ mt: 2, maxWidth: "100%", maxHeight: 200 }}
                >
                    <Table
                        stickyHeader
                        sx={{
                            "& th": {
                                borderBottom: "1px solid #D9D2E6 !important",
                                border: "1px solid #D9D2E6",
                                backgroundColor: "#FFF !important",
                                padding: "8px !important",
                                width: "auto",
                                whiteSpace: "nowrap",
                            },
                            "& td": {
                                padding: "8px !important",
                                border: "1px solid #D9D2E6",
                                width: "auto",
                                whiteSpace: "nowrap",
                            },
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                {hours.map((h) => (
                                    <TableCell
                                        key={h}
                                        align="center"
                                    >{`H${h}`}</TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Object.keys(usages)
                                .reverse()
                                .map((key) => (
                                    <TableRow key={key}>
                                        <TableCell>{key}</TableCell>
                                        {usages[key].map((usg) => (
                                            <TableCell
                                                key={generateRandomId()}
                                                align="center"
                                            >
                                                <MeasurementState
                                                    measurement={usg}
                                                    date={key}
                                                    hour={moment(
                                                        usg.time_start,
                                                    ).format("HH")}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default Measurements;
