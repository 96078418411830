import { Box, Grid } from "@enerbit/base";
import { useNavigate, useParams } from "react-router-dom";
import GeneralInfo from "./components/general-info";
import MetersHistory from "./components/meters-history";
import Actions from "./components/actions";
import DetailHeader from "./components/detail-header";
import ClientData from "./components/client-data";
import RelatedInfo from "./components/related-info";
import Submeasurements from "./components/submeasurements";
import SectionContainer from "../../components/container/section-container";
import { StyledTab } from "../../components/Tabs/StyledTab";
import { StyledTabs } from "../../components/Tabs/StyledTabs";
import { TabPanel } from "../../components/Tabs/TabPanel";
import { useEffect, useState } from "react";
import Measurements from "./components/measurements";
import OrderHistorical from "./components/order-historical";

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const MeasurementPointDetail = () => {
    const { id } = useParams<{ id: string }>();
    const [value, setValue] = useState<number>(0);

    const navigate = useNavigate();

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        if (!id) navigate("/");
    }, []);

    return (
        <>
            {id && (
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                        }}
                    >
                        <DetailHeader id={id} />
                        <Actions />
                    </Box>
                    <Grid container columnSpacing={3} sx={{ mt: 3 }}>
                        <Grid item xs={9}>
                            <Box>
                                <GeneralInfo id={id} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <MetersHistory id={id} />
                            </Box>
                            <SectionContainer sx={{ mt: 2 }}>
                                <Box>
                                    <Box
                                        sx={{
                                            borderBottom: 1,
                                            borderColor: "divider",
                                        }}
                                    >
                                        <StyledTabs
                                            value={value}
                                            onChange={handleChange}
                                        >
                                            <StyledTab
                                                label={<Box>Datos</Box>}
                                                {...a11yProps(0)}
                                            />
                                            <StyledTab
                                                label={
                                                    <Box>
                                                        Historial órdenes de
                                                        servicio
                                                    </Box>
                                                }
                                                {...a11yProps(1)}
                                            />
                                        </StyledTabs>
                                    </Box>
                                    <TabPanel value={value} index={0}>
                                        <Measurements id={id} />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <OrderHistorical id={id} />
                                    </TabPanel>
                                </Box>
                            </SectionContainer>
                        </Grid>
                        <Grid item xs={3}>
                            <Box>
                                <ClientData id={id} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <RelatedInfo id={id} />
                            </Box>
                            <Box sx={{ mt: 2 }}>
                                <Submeasurements id={id} />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default MeasurementPointDetail;
