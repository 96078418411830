import { api } from "@enerbit/base";
import { SearchOrdersParams, SearchOrdersResponse } from "../models";

export const getOrdersService = async ({ id, page }: SearchOrdersParams) => {
    try {
        const params = { page, assignee_id: id, size: 10, entity_code: "MP" };
        const res = await api.get<SearchOrdersResponse>(
            "/service-orders/orders",
            { params },
        );

        return {
            items: res.data.items,
            currentPage: res.data.page,
            totalPages: res.data.pages,
        };
    } catch (_error) {
        throw new Error("ErrGettingOrders");
    }
};
