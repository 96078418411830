import {
    Box,
    Button,
    CustomAlert,
    enerbitColors,
    Pagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from "@enerbit/base";
import Filters from "./components/filters";
import { StyledTableHeader } from "../../components/Table/StyledTableHeader";
import { useEffect } from "react";
import { useMeasurementPointState } from "../../store/measurement-point";
import { useNavigate } from "react-router-dom";
import PaginationContainer from "../../components/container/pagination-container";
import TableSkeleton from "../../components/Table/TableSkeleton";

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
};

const TABLE_HEADERS: string[] = [
    "ID",
    "Latitud",
    "Longitud",
    "Tipo de medida",
    "Acciones",
];

const MeasurementPointList = () => {
    const { measurementPoints, searchMeasurmentPoints, loading, error } =
        useMeasurementPointState((state) => state);

    const navigate = useNavigate();

    useEffect(() => {
        searchMeasurmentPoints({ page: 0 });
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        searchMeasurmentPoints({ page: value - 1 });
    };

    return (
        <Box>
            <Typography
                sx={{
                    color: enerbitColors.primary.main,
                    fontWeight: 700,
                    fontSize: "28px",
                }}
            >
                Punto de medida
            </Typography>
            <Box sx={{ mt: 3 }}>
                <Filters />
            </Box>
            {loading && (
                <TableContainer sx={{ mt: 2 }}>
                    <TableSkeleton columns={7} rows={20} />
                </TableContainer>
            )}
            {!loading && !error && measurementPoints.items.length > 0 && (
                <>
                    <TableContainer sx={{ mt: 2 }}>
                        <Table>
                            <StyledTableHeader>
                                {TABLE_HEADERS.map((header, index) => (
                                    <TableCell key={`${index + 1}-${header}`}>
                                        {header}
                                    </TableCell>
                                ))}
                            </StyledTableHeader>
                            <TableBody>
                                {measurementPoints.items.map((mp) => (
                                    <TableRow key={mp.id}>
                                        <TableCell>{mp.id}</TableCell>
                                        <TableCell>{mp.latitude}</TableCell>
                                        <TableCell>{mp.longitude}</TableCell>
                                        <TableCell>
                                            <Typography
                                                sx={{
                                                    fontWeight: 700,
                                                    color: enerbitColors.primary
                                                        .main,
                                                }}
                                            >
                                                {mp.measuringType}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    navigate(`/${mp.id}`)
                                                }
                                            >
                                                Ver detalle
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={measurementPoints.currentPage + 1}
                                count={measurementPoints.totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="error"
                    text={"Error listando puntos de medida."}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && measurementPoints.items.length === 0 && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="info"
                    text={"No se encontraron puntos de medida."}
                    onClose={() => {}}
                />
            )}
        </Box>
    );
};

export default MeasurementPointList;
