import { api } from "@enerbit/base";
import {
    MeasurementPoint,
    SearchMeasurementPointsParams,
    SearcMeasurementTypeResponse,
} from "../models";

export const searchMeasurementPointService = async ({
    page,
    filters,
}: SearchMeasurementPointsParams) => {
    try {
        const params: Record<string, string | number> = { page, size: 50 };

        if (filters) {
            Object.keys(filters).forEach((key) => {
                if (filters[key]) params[key] = filters[key];
            });
        }

        const res = await api.get<SearcMeasurementTypeResponse>(
            "/odin/measurement-point/measurement-point",
            { params },
        );

        return {
            items: res.data.items,
            totalPages: res.data.pages,
            currentPage: res.data.page,
        };
    } catch (_error) {
        throw new Error("ErrListEstimations");
    }
};

export const getMeasurementPointService = async (id: string) => {
    try {
        const res = await api.get<MeasurementPoint>(
            `/odin/measurement-point/measurement-point/${id}`,
        );

        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMeasurementPoint");
    }
};

export const getChildPointsService = async (
    id: string,
    page: number,
    size?: number,
) => {
    try {
        const params: Record<string, string | number> = {
            page,
            size: size ?? 10,
            parent_point_id: id,
        };

        const res = await api.get<SearcMeasurementTypeResponse>(
            "/odin/measurement-point/measurement-point",
            { params },
        );

        const { items, total, page: currentPage, pages: totalPages } = res.data;
        return { items, total, currentPage, totalPages };
    } catch (_error) {
        throw new Error("ErrGettingChildPoints");
    }
};
