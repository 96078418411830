import { create } from "zustand";
import { FrontiersState } from "../models/frontiers";
import { getMpFrontierService } from "../services";

export const useRelatedData = create<FrontiersState>()((set) => ({
    loading: false,
    error: false,
    frontiers: [],
    getMpFrontier: async (id: string) => {
        set({ loading: true, error: false });
        try {
            const frontiers = await getMpFrontierService(id);
            set({ frontiers });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
