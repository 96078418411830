import ShortUniqueId from "short-unique-id";
import { Usages, Usagestatus } from "../models/usages";
import { DownloadFileParams } from "../models/file";
import moment from "moment";
import Papa from "papaparse";

export const generateRandomId = () => {
    const { randomUUID } = new ShortUniqueId({ length: 10 });

    return randomUUID();
};

export const generateCSV = (usages: Record<string, Usages[]>, id: string) => {
    const fileRows = Object.keys(usages).map((key) => {
        // biome-ignore lint/suspicious/noExplicitAny: <explanation>
        const row: Record<string, any> = {
            fecha: key,
        };
        usages[key].forEach((usg) => {
            const hour = "H" + moment(usg.time_start).format("HH");

            if (usg.status) {
                row[hour] =
                    usg.status !== Usagestatus.MISSING &&
                    usg.status !== Usagestatus.FUTURE
                        ? usg.active_energy_imported
                        : "NULL";
            }
        });
        return row;
    });

    const headers = Object.keys(fileRows[0]);

    downloadFile(fileRows, `consumos_punto_de_medida_${id}`, headers);
};

export const createDownloadLink = ({
    data,
    fileName,
    fileType,
}: DownloadFileParams) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const downloadFile = (
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    data: any[],
    fileName: string,
    headers: string[],
) => {
    const csv = Papa.unparse({
        fields: headers,
        data: data,
    });

    createDownloadLink({
        data: "\uFEFF" + csv,
        fileName,
        fileType: "text/csv;charset=utf-8;",
    });
};
