import {
    Box,
    Typography,
    enerbitColors,
    IconButton,
    ArrowForwardIcon,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { MeasurementPoint } from "../../../models";
import { useNavigate } from "react-router-dom";

interface Props {
    mp: MeasurementPoint;
}

const MeasurementPointItem = ({ mp }: Props) => {
    const navigate = useNavigate();

    const goToMpDetail = (id: string) => {
        navigate(`/${id}`);
    };

    return (
        <SectionContainer
            key={mp.id}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                borderRadius: "4px",
            }}
        >
            <Box>
                <Typography
                    sx={{
                        color: enerbitColors.neutral.main,
                        fontSize: "12px",
                    }}
                >
                    Punto de medida hijo
                </Typography>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.neutral[900],
                    }}
                >
                    {mp.id}
                </Typography>
            </Box>
            <IconButton
                onClick={() => {
                    goToMpDetail(mp.id);
                }}
                sx={{
                    backgroundColor: "#FF7705",
                    borderRadius: "12px",
                    height: "32px",
                    width: "32px",
                    ":hover": {
                        backgroundColor: "#FF7705",
                    },
                }}
            >
                <ArrowForwardIcon sx={{ color: "white" }} />
            </IconButton>
        </SectionContainer>
    );
};

export default MeasurementPointItem;
