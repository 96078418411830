import { api } from "@enerbit/base";
import {
    IModelsResponse,
    Meter,
    MeterRelationshipsResponse,
} from "../models/meters";

export const getMeterModels = async () => {
    try {
        const res = await api.get<IModelsResponse>("/meters/models/?size=150");
        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingModels");
    }
};

export const getMeter = async (id: string) => {
    try {
        const res = await api.get<Meter>(`/odin/meters/meters/${id}`);
        return res.data;
    } catch (_error) {
        throw new Error("ErrGettingMeter");
    }
};

export const getMeterRelationships = async (id: string) => {
    try {
        const res = await api.get<MeterRelationshipsResponse>(
            `/odin/measurement-point/measurement-point/meters-relationships?measurement_point_id=${id}&size=50&page=0`,
        );

        return res.data.items;
    } catch (_error) {
        throw new Error("ErrGettingMeterRelationships");
    }
};
