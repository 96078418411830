import { HashRouter, Route, Routes } from "react-router-dom";
import MeasurementPointList from "../pages/measurement-point-list";
import MeasurementPointDetail from "../pages/detail";

const Router = () => {
    return (
        <HashRouter basename="/measurement-point">
            <Routes>
                <Route path="/" element={<MeasurementPointList />} />
                <Route path="/:id" element={<MeasurementPointDetail />} />
            </Routes>
        </HashRouter>
    );
};

export default Router;
