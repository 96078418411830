import { create } from "zustand";
import { MeasurementPointDetailState } from "../models";
import { getMeasurementPointService } from "../services";
import { searchMeteringTypesService } from "../services/metering-types";
import moment from "moment";
import { DATE_TIME_FORMAT } from "../common";

export const useGeneralInfo = create<MeasurementPointDetailState>()((set) => ({
    measurementPointDetail: null,
    error: false,
    loading: false,
    getMeasurementPoint: async (id: string) => {
        try {
            set({ error: false, loading: true });
            const meteringTypes = await searchMeteringTypesService();
            const measurementPoint = await getMeasurementPointService(id);

            set({
                measurementPointDetail: {
                    ...measurementPoint,
                    metering_type_id:
                        meteringTypes.find(
                            (mt) => mt.id === measurementPoint.metering_type_id,
                        )?.type_name ?? "Desconocido",
                    created_at: moment(measurementPoint.created_at).format(
                        DATE_TIME_FORMAT,
                    ),
                    updated_at: moment(measurementPoint.updated_at).format(
                        DATE_TIME_FORMAT,
                    ),
                },
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
