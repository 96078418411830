import {
    Box,
    Button,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Typography,
} from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import { useSubmeasurements } from "../../../store/submeasurements";
import { useEffect, useState } from "react";
import ChildPointsModal from "./child-points-modal";
import MeasurementPointItem from "./measurement-point-item";

interface Props {
    id: string;
}

const Submeasurements = ({ id }: Props) => {
    const { loading, error, getChildPoints, measurementPoints, totalChilds } =
        useSubmeasurements((state) => state);
    const [showAll, setShowAll] = useState<boolean>(false);

    useEffect(() => {
        getChildPoints(id, 0);
    }, [id]);

    return (
        <>
            <SectionContainer>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "18px",
                    }}
                >
                    Dispositivos de submedición
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                {loading && !error && (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress />
                    </Box>
                )}
                {!loading && error && (
                    <CustomAlert
                        severity="error"
                        text={
                            "Error cargando información de los puntos de medida hijo."
                        }
                        onClose={() => {}}
                    />
                )}
                {!loading && !error && totalChilds === 0 && (
                    <CustomAlert
                        severity="info"
                        text={"Sin puntos de medida hijos."}
                        onClose={() => {}}
                    />
                )}
                {!loading && !error && totalChilds > 0 && (
                    <>
                        {measurementPoints.slice(0, 3).map((mp) => (
                            <MeasurementPointItem key={mp.id} mp={mp} />
                        ))}
                        {totalChilds > 3 && (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <Button
                                    variant="text"
                                    color="secondary"
                                    sx={{ mt: 2 }}
                                    onClick={() => setShowAll(true)}
                                >
                                    Ver todos
                                </Button>
                            </Box>
                        )}
                    </>
                )}
            </SectionContainer>
            <ChildPointsModal
                open={showAll}
                handleClose={() => setShowAll(false)}
                id={id}
            />
        </>
    );
};

export default Submeasurements;
