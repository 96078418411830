import { initEnviroment, ThemeConfig } from "@enerbit/base";
import { hot } from "react-hot-loader/root";
import MainLayout from "./layouts/main-layout";
import Router from "./router/router";

initEnviroment({
    baseUrl: process.env.REACT_APP_BASE_URL,
    geopoliticsBaseUrl: process.env.REACT_APP_BASE_GEOPOLITICS_URL,
});

export default hot(function Root() {
    return (
        <ThemeConfig>
            <MainLayout>
                <Router />
            </MainLayout>
        </ThemeConfig>
    );
});
