import { api } from "@enerbit/base";
import { MeteringTypesResponse } from "../models/metering-types";

export const searchMeteringTypesService = async () => {
    try {
        const res = await api.get<MeteringTypesResponse>(
            "/odin/measurement-point/metering-types",
            { params: { size: 10, page: 0 } },
        );

        return res.data.items;
    } catch (_error) {
        throw new Error("ErrSearchingMeteringTypes");
    }
};
