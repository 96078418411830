import { create } from "zustand";
import { MeterHistoryData, MeterHistoryState } from "../models/meters";
import { getMeter, getMeterRelationships } from "../services/meters";
import { DATE_TIME_FORMAT } from "../common";
import moment from "moment";

export const useMetersHistory = create<MeterHistoryState>()((set) => ({
    meters: [],
    loading: false,
    error: false,
    totalMeters: 0,
    getMetersHistory: async (id: string) => {
        set({ loading: true, error: false });
        try {
            const relationships = await getMeterRelationships(id);

            const tempMeters = relationships.items.map(async (rel) => {
                const meterDetail = await getMeter(rel.meter_id);
                const data: MeterHistoryData = {
                    serial: meterDetail.serial,
                    model: meterDetail.meter_model.name,
                    since: moment(rel.started_at).format(DATE_TIME_FORMAT),
                    until: rel.ended_at
                        ? moment(rel.ended_at).format(DATE_TIME_FORMAT)
                        : "Actualidad",
                    active: !rel.ended_at,
                };

                return data;
            });

            const formattedMeters = await Promise.all(tempMeters);

            set({ meters: formattedMeters, totalMeters: relationships.total });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
