export interface UsagesState {
    loading: boolean;
    error: boolean;
    usages: Record<string, Usages[]> | null;
    getUsages: (props: GetUsagesParams) => Promise<void>;
}

export interface Usages {
    active_energy_imported: number;
    reactive_energy_imported: number;
    active_energy_exported: number;
    reactive_energy_exported: number;
    measurement_point_id: string;
    time_start: string;
    time_end: string;
    is_estimated: boolean;
    status?: Usagestatus;
}

export interface GetUsagesParams {
    id: string;
    since: string;
    until: string;
}

export enum Usagestatus {
    EXISTS = "exists",
    MISSING = "missing",
    FUTURE = "future",
    ESTIMATED = "estimated",
}
