import { Box, enerbitColors, Grid, styled, Typography } from "@enerbit/base";
import SectionContainer from "../../../components/container/section-container";
import ChargingStationIcon from "@mui/icons-material/ChargingStation";
import StatusTag from "../../../components/container/status-tag";
import { MeterHistoryData } from "../../../models/meters";
import { generateRandomId } from "../../../utils/common";

interface Props {
    meter: MeterHistoryData;
}

const meterDataKeys: { label: string; key: keyof MeterHistoryData }[] = [
    {
        label: "Serial de medidor",
        key: "serial",
    },
    { label: "Modelo de medidor", key: "model" },
    { label: "Desde", key: "since" },
    { label: "Hasta", key: "until" },
];

const MeterItem = ({ meter }: Props) => {
    return (
        <SectionContainer sx={{ borderRadius: "4px" }}>
            <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                <IconContainer bgcolor={"#8B76D6"}>
                    <ChargingStationIcon sx={{ color: "#FFF" }} />
                </IconContainer>
                <Grid container columnSpacing={2}>
                    {meterDataKeys.map((value) => (
                        <Grid
                            item
                            xs={12 / meterDataKeys.length}
                            key={generateRandomId()}
                        >
                            <Typography
                                sx={{
                                    color: enerbitColors.neutral.main,
                                    fontSize: "12px",
                                }}
                            >
                                {value.label}
                            </Typography>
                            <Typography
                                sx={{
                                    fontWeight: 700,
                                    color: enerbitColors.neutral[900],
                                }}
                            >
                                {meter[value.key]}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                    <StatusTag
                        bgcolor={
                            meter.active
                                ? enerbitColors.success[100]
                                : enerbitColors.error[100]
                        }
                        fontcolor={
                            meter.active
                                ? enerbitColors.success.main
                                : enerbitColors.error.main
                        }
                    >
                        Activo
                    </StatusTag>
                    {/* <IconButton
                        sx={{
                            backgroundColor: "#FF7705",
                            borderRadius: "12px",
                            height: "32px",
                            width: "32px",
                            ":hover": {
                                backgroundColor: "#FF7705",
                            },
                        }}
                    >
                        <ArrowForwardIcon sx={{ color: "white" }} />
                    </IconButton> */}
                </Box>
            </Box>
        </SectionContainer>
    );
};

export default MeterItem;

interface IconContainerProps {
    bgcolor: string;
}

// biome-ignore lint/suspicious/noExplicitAny: <explanation>
const IconContainer: any = styled(Box)<IconContainerProps>(({ bgcolor }) => ({
    backgroundColor: bgcolor,
    height: "40px",
    width: "40px",
    padding: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "6px",
}));
