import StyledModal from "../../../components/container/styled-modal";
import { generateRandomId } from "../../../utils/common";
import { Box, CustomAlert, Pagination, Skeleton, Stack } from "@enerbit/base";
import PaginationContainer from "../../../components/container/pagination-container";
import MeasurementPointItem from "./measurement-point-item";
import { useEffect, useState } from "react";
import { getChildPointsService } from "../../../services";
import { MeasurementPoint } from "../../../models";

interface Props {
    open: boolean;
    handleClose: () => void;
    id: string;
}

const ChildPointsModal = ({ open, handleClose, id }: Props) => {
    // const {
    //     loading,
    //     error,
    //     getChildPoints,
    //     measurementPoints,
    //     currentPage,
    //     totalPages,
    // } = useSubmeasurements((state) => state);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [totalPages, setTotalPages] = useState<number>(0);
    const [measurementPoints, setMeasurementPoints] = useState<
        MeasurementPoint[]
    >([]);

    const fetchMeasurementePoints = async (id: string, page: number) => {
        setError(false);
        setLoading(true);
        try {
            const mps = await getChildPointsService(id, page);
            setCurrentPage(mps.currentPage);
            setTotalPages(mps.totalPages);
            setMeasurementPoints(mps.items);
        } catch (_error) {
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (open) {
            fetchMeasurementePoints(id, 0);
        }
    }, [id, open]);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        setCurrentPage(value - 1);
        fetchMeasurementePoints(id, value - 1);
    };

    return (
        <StyledModal
            open={open}
            onClose={handleClose}
            title="Puntos de medida hijos"
        >
            {loading && (
                <>
                    {[...Array(7)].map(() => (
                        <Skeleton
                            variant="rectangular"
                            key={generateRandomId()}
                            height={56}
                            sx={{ mb: 2 }}
                        />
                    ))}
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    severity="error"
                    text={
                        "Error cargando información de los puntos de medida hijo."
                    }
                    onClose={() => {}}
                />
            )}
            {!loading && !error && (
                <>
                    {measurementPoints.map((mp) => (
                        <Box key={mp.id} sx={{ mb: 1 }}>
                            <MeasurementPointItem mp={mp} />
                        </Box>
                    ))}
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={currentPage + 1}
                                count={totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
        </StyledModal>
    );
};

export default ChildPointsModal;
