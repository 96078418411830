import {
    Box,
    Button,
    CachedIcon,
    InputLabel,
    MenuItem,
    SearchIcon,
} from "@enerbit/base";
import CustomSelect from "../../../components/Inputs/CustomSelect";
import { useMeasurementPointState } from "../../../store/measurement-point";
import { useState } from "react";
import { ObjectKeyValue } from "@enerbit/base/common/models/customPropsComponents";

const Filters = () => {
    const { meteringTypes, loading, searchMeasurmentPoints } =
        useMeasurementPointState();
    const [filters, setFilters] = useState<ObjectKeyValue>({
        metering_type_id: "",
    });

    const handleSearch = async () => {
        await searchMeasurmentPoints({ page: 0, filters });
    };

    const handleReset = async () => {
        setFilters({ metering_type_id: "" });
        await searchMeasurmentPoints({ page: 0 });
    };

    const handleFilterChange = (key: string, value: string) => {
        setFilters((prev) => {
            const nD = { ...prev };
            nD[key] = value;
            return nD;
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                {/* <Box>
                    <InputLabel>Buscar por</InputLabel>
                    <FormControl>
                        <CustomTextField
                            fullWidth
                            sx={{ width: "295px" }}
                            placeholder="Buscar por id"
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        sx={{
                                            color: enerbitColors.neutral[700],
                                            fontSize: "12",
                                            mr: 1,
                                        }}
                                    />
                                ),
                            }}
                        />
                    </FormControl>
                </Box> */}
                <Box>
                    <InputLabel>Tipo de medida</InputLabel>
                    <CustomSelect
                        fullWidth
                        sx={{ width: "295px" }}
                        defaultValue={""}
                        value={filters["metering_type_id"]}
                        onChange={(e) => {
                            handleFilterChange(
                                "metering_type_id",
                                e.target.value as string,
                            );
                        }}
                        disabled={loading}
                    >
                        {meteringTypes.map((mt) => (
                            <MenuItem key={mt.id} value={mt.id}>
                                {mt.type_name}
                            </MenuItem>
                        ))}
                    </CustomSelect>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ mt: 2 }}
                        onClick={handleSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Box>
                <Box>
                    <Button variant="text" sx={{ mt: 2 }} onClick={handleReset}>
                        <CachedIcon />
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default Filters;
