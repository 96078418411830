import { useOrdersState } from "../../../store/orders";
import { useEffect } from "react";
import {
    TableContainer,
    Table,
    TableCell,
    TableBody,
    TableRow,
    Stack,
    Pagination,
    CustomAlert,
    SxProps,
    Button,
    ArrowRightIcon,
} from "@enerbit/base";
import PaginationContainer from "../../../components/container/pagination-container";
import { StyledTableHeader } from "../../../components/Table/StyledTableHeader";
import TableSkeleton from "../../../components/Table/TableSkeleton";
import StatusTag from "../../../components/container/status-tag";
import { DATE_TIME_FORMAT, ORDER_TYPES_TO_NAMES } from "../../../common";
import moment from "moment";
import { navigateToUrl } from "single-spa";

interface Props {
    id: string;
}

const TABLE_HEADERS: string[] = ["Tipo", "Estado", "Fecha", "Detalle"];

const detailButtonProps: SxProps = {
    minHeight: "unset !important",
    height: "33px",
    fontSize: "12px",
};

const OrderHistorical = ({ id }: Props) => {
    const { loading, error, getOrders, orders } = useOrdersState(
        (state) => state,
    );

    useEffect(() => {
        getOrders({ page: 0, id });
    }, []);

    const handlePagination = (
        event: React.ChangeEvent<unknown>,
        value: number,
    ) => {
        event.preventDefault();
        getOrders({ page: value - 1, id });
    };

    const goToOrderServices = (orderId: string) => {
        navigateToUrl(`/#/orders/${orderId}`);
    };

    return (
        <>
            {loading && (
                <TableContainer sx={{ mt: 2 }}>
                    <TableSkeleton columns={4} rows={3} />
                </TableContainer>
            )}
            {!loading && !error && orders.items.length > 0 && (
                <>
                    <TableContainer sx={{ mt: 2 }}>
                        <Table>
                            <StyledTableHeader>
                                {TABLE_HEADERS.map((header, index) => (
                                    <TableCell key={`${index + 1}-${header}`}>
                                        {header}
                                    </TableCell>
                                ))}
                            </StyledTableHeader>
                            <TableBody>
                                {orders.items.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell>
                                            {order.order_type.name}
                                        </TableCell>
                                        <TableCell>
                                            <StatusTag
                                                fontcolor={
                                                    ORDER_TYPES_TO_NAMES[
                                                        order.status
                                                    ].font
                                                }
                                                bgcolor={
                                                    ORDER_TYPES_TO_NAMES[
                                                        order.status
                                                    ].bgcolor
                                                }
                                            >
                                                {
                                                    ORDER_TYPES_TO_NAMES[
                                                        order.status
                                                    ].label
                                                }
                                            </StatusTag>
                                        </TableCell>
                                        <TableCell>
                                            {moment(
                                                order.planned_date_begin,
                                            ).format(DATE_TIME_FORMAT)}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                sx={detailButtonProps}
                                                onClick={() =>
                                                    goToOrderServices(order.id)
                                                }
                                            >
                                                <ArrowRightIcon />
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <PaginationContainer sx={{ mt: 2 }}>
                        <Stack spacing={2}>
                            <Pagination
                                page={orders.currentPage + 1}
                                count={orders.totalPages}
                                shape="rounded"
                                onChange={handlePagination}
                            />
                        </Stack>
                    </PaginationContainer>
                </>
            )}
            {!loading && error && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="error"
                    text={"Error listando órdenes de servicio."}
                    onClose={() => {}}
                />
            )}
            {!loading && !error && orders.items.length === 0 && (
                <CustomAlert
                    sx={{ mt: 2 }}
                    severity="info"
                    text={
                        "No se encontraron órdenes de servicio para este punto de medida."
                    }
                    onClose={() => {}}
                />
            )}
        </>
    );
};

export default OrderHistorical;
