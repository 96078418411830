import { create } from "zustand";
import { SubmeasurementsState } from "../models";
import { getChildPointsService } from "../services";

export const useSubmeasurements = create<SubmeasurementsState>()((set) => ({
    loading: false,
    error: false,
    measurementPoints: [],
    totalChilds: 0,
    currentPage: 0,
    totalPages: 0,
    getChildPoints: async (id, page, size = 10) => {
        set({ loading: true, error: false });
        try {
            const response = await getChildPointsService(id, page, size);

            set({
                measurementPoints: response.items,
                totalChilds: response.total,
                totalPages: response.totalPages,
                currentPage: response.currentPage,
            });
        } catch (_error) {
            set({ error: true });
        } finally {
            set({ loading: false });
        }
    },
}));
