import { create } from "zustand";
import {
    MeasurementPointState,
    MeasurementPointTableData,
    SearchMeasurementPointsParams,
} from "../models";
import {
    searchMeasurementPointService,
} from "../services";
import { searchMeteringTypesService } from "../services/metering-types";

export const useMeasurementPointState = create<MeasurementPointState>()(
    (set) => ({
        measurementPoints: { items: [], currentPage: 0, totalPages: 0 },
        meteringTypes: [],
        loading: false,
        error: false,
        searchMeasurmentPoints: async ({
            page,
            filters,
        }: SearchMeasurementPointsParams) => {
            set({ loading: true, error: false });
            try {
                const meteringTypes = await searchMeteringTypesService();
                const measurementPoints = await searchMeasurementPointService({
                    page,
                    filters,
                });
                const { currentPage, items, totalPages } = measurementPoints;

                const response: MeasurementPointTableData[] = items.map(
                    (mp) => ({
                        id: mp.id,
                        latitude: mp.latitude,
                        longitude: mp.longitude,
                        measuringType:
                            meteringTypes.find(
                                (mt) => mt.id === mp.metering_type_id,
                            )?.type_name ?? "Desconocido",
                    }),
                );
                set({
                    measurementPoints: {
                        currentPage,
                        items: response,
                        totalPages,
                    },
                    meteringTypes: meteringTypes,
                });
            } catch (_error) {
                set({ error: true });
            } finally {
                set({ loading: false });
            }
        }
    }),
);
