import { create } from "zustand";
import { ElectricitySupplyServiceState } from "../models/ess";
import { getEssByMpService } from "../services";

export const useElectricitySupplyService =
    create<ElectricitySupplyServiceState>()((set) => ({
        loading: false,
        error: false,
        electricitySuppluServices: [],
        getEssByMp: async (id: string) => {
            set({ loading: true, error: false });
            try {
                const response = await getEssByMpService(id);

                set({ electricitySuppluServices: response });
            } catch (_error) {
                set({ error: true });
            } finally {
                set({ loading: false });
            }
        },
    }));
