import {
    Box,
    CircularProgress,
    CustomAlert,
    Divider,
    enerbitColors,
    Grid,
    Typography,
} from "@enerbit/base";
import { useGeneralInfo } from "../../../store/detail";
import { useEffect } from "react";
import { MeasurementPoint } from "../../../models";
import SectionContainer from "../../../components/container/section-container";

interface Props {
    id: string;
}

const generalInfoData: { label: string; key: keyof MeasurementPoint }[] = [
    { label: "Latitud", key: "latitude" },
    { label: "Longitud", key: "longitude" },
    { label: "Tipo de medida", key: "metering_type_id" },
    { label: "Código Plus", key: "plus_code" },
    { label: "Fecha de creación", key: "created_at" },
    { label: "Última actualización", key: "updated_at" },
];

const GeneralInfo = ({ id }: Props) => {
    const { error, loading, measurementPointDetail, getMeasurementPoint } =
        useGeneralInfo((state) => state);

    useEffect(() => {
        getMeasurementPoint(id);
    }, []);

    return (
        <>
            <SectionContainer>
                <Typography
                    sx={{
                        fontWeight: 700,
                        color: enerbitColors.primary.main,
                        fontSize: "18px",
                    }}
                >
                    Información
                </Typography>
                <Divider sx={{ my: 1, mx: "-12px" }} />
                <Box>
                    {!loading && measurementPointDetail && !error && (
                        <Grid container rowSpacing={2} columnSpacing={2}>
                            {generalInfoData.map((info) => (
                                <Grid item xs={3} key={info.key}>
                                    <Typography
                                        sx={{
                                            color: enerbitColors.neutral.main,
                                            fontSize: "12px",
                                        }}
                                    >
                                        {info.label}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            color: enerbitColors.neutral[900],
                                        }}
                                    >
                                        {measurementPointDetail[info.key]}
                                    </Typography>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {!loading && !measurementPointDetail && error && (
                        <CustomAlert
                            severity="error"
                            text={"Error cargando información general."}
                            onClose={() => {}}
                        />
                    )}
                    {loading && (
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <CircularProgress />
                        </Box>
                    )}
                </Box>
            </SectionContainer>
        </>
    );
};

export default GeneralInfo;
